<template>
    <div>
        <div v-if="$projectInfo.sql_assistant" style="height: 70vh">
            <iframe :src="$projectInfo.sql_assistant" width="100%" height="100%" style="border: none" />
        </div>
        <div v-else class="generator-not-found">
            <h3>404</h3>
            <h4>{{ $l("sql_not_found", "Sql generator assistant not found") }}</h4>
        </div>
    </div>
</template>

<script>
export default {
    name: "SqlGenerator",

    data() {
        return {
            inputVal: ""
        }
    },

    mounted() {
        // window.postMessage({
        //     baseUrl: process.env.API_BASE_URL,
        //     token: localStorage.getItem('user_token'),
        // }, "*");

        window.onmessage = (event) => {
            this.inputVal = event.data
        }
    }
}
</script>

<style>
.generator-not-found {
    gap: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
}
</style>
