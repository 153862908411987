import { codemirror } from "vue-codemirror"

Vue.component("codemirror", codemirror)

// import base style
import "codemirror/lib/codemirror.css"
import "codemirror/addon/mode/overlay.js"
import "codemirror/addon/display/fullscreen"
import "codemirror/addon/display/fullscreen.css"
// import language js
import "codemirror/mode/javascript/javascript.js"
import "codemirror/mode/sql/sql"
import "codemirror/mode/css/css"
import "codemirror/mode/htmlmixed/htmlmixed"
import "codemirror/mode/vue/vue"

// import theme style
import "codemirror/theme/base16-light.css"
import "codemirror/theme/monokai.css"
import "codemirror/theme/darcula.css"
import "codemirror/theme/dracula.css"

//fold Gutter
import "codemirror/addon/fold/foldgutter.css"
import "codemirror/addon/fold/brace-fold.js"
import "codemirror/addon/fold/comment-fold.js"
import "codemirror/addon/fold/foldcode.js"
import "codemirror/addon/fold/foldgutter.js"
import "codemirror/addon/fold/indent-fold.js"
import "codemirror/addon/fold/markdown-fold.js"
import "codemirror/addon/fold/xml-fold.js"
// import hint and keymap
import "codemirror/addon/hint/anyword-hint.js"
import "codemirror/addon/hint/javascript-hint.js"
import "codemirror/addon/hint/sql-hint.js"
import "codemirror/addon/hint/xml-hint.js"
import "codemirror/addon/hint/show-hint.js"
import "codemirror/addon/hint/css-hint.js"
import "codemirror/addon/hint/show-hint.css"
import "codemirror/addon/hint/html-hint.js"

//keymap
import "codemirror/mode/clike/clike.js"
import "codemirror/addon/edit/matchbrackets.js"
import "codemirror/addon/edit/closebrackets.js"
import "codemirror/addon/edit/closetag.js"

import "codemirror/addon/comment/comment.js"
import "codemirror/addon/dialog/dialog.js"
import "codemirror/addon/dialog/dialog.css"
import "codemirror/addon/search/search.js"
import "codemirror/addon/search/searchcursor.js"
import "codemirror/keymap/sublime.js"
//highlightSelectMatches
import "codemirror/addon/scroll/annotatescrollbar.js"
import "codemirror/addon/search/matchesonscrollbar.js"
import "codemirror/addon/search/match-highlighter.js"
// styleSelectedText
import "codemirror/addon/selection/mark-selection.js"
// active-line.js
import "codemirror/addon/selection/active-line.js"
