<template>
    <BaseControl v-bind="$attrs" :errors="combinedErrors">
        <div :class="{ 'pl-codemirror-wrapper': isSql }">
            <codemirror
                :id="id"
                :placeholder="placeholder"
                ref="editor"
                :value="local"
                :options="codeOptions"
                :name="name"
                @ready="onCmReady"
                @input="updateLocal"
            />

            <btn variant="primary" v-if="isSql" @click.native="generateSql">
                <img src="../../../assets/sql-generator.svg" alt="sql-generate" />
            </btn>
        </div>

        <div @click="fullscreen()" class="fullscreen p-2" :class="{ 'sql-fullscreen': isSql }">
            <i class="fa fa-expand-arrows-alt primary-text" />
        </div>

        <div v-if="hasUndo" @click="undo()" class="undo p-2" :class="{ 'sql-undo': isSql }">
            <i class="fa fa-undo primary-text" />
        </div>

        <div
            v-if="isFullscreen && hasUndo"
            class="position-fixed undo-btn code-editor-btn btn btn-secondary"
            @click="undo"
        >
            <i class="fa fa-undo primary-text" />
            <span> [Ctrl+u] </span>
        </div>
        <div v-if="isFullscreen" class="position-fixed cancel-btn code-editor-btn btn btn-secondary" @click="exit">
            {{ $l("platon.code_editor_cancel", "Бекор қилиш") }}
            <span> [Esc] </span>
        </div>
        <div v-if="isFullscreen" class="position-fixed save-btn code-editor-btn btn btn-primary" @click="save">
            {{ $l("platon.code_editor_save", "Сақлаш") }}
            <span> [Ctrl+s] </span>
        </div>
    </BaseControl>
</template>

<script>
import BaseControl from "@Platon/components/form/controls/BaseControl.vue"
import InputControlMixin from "@Platon/mixins/InputControlMixin"
import ValidationMixin from "@Platon/mixins/ValidationMixin"
import "@Platon/external/codemirror/CodeMirrorImports.js"
import Btn from "@Platon/components/extended/Btn.vue"
import SqlGenerator from "./SqlGenerator.vue"

const mime = {
    js: "text/javascript",
    sql: "text/x-sql",
    css: "text/css",
    html: "text/html"
}

export default {
    name: "CodeControl",
    title: "Mode: text/x-vue & Theme: base16-dark",
    components: { BaseControl, Btn },

    mixins: [InputControlMixin, ValidationMixin],

    props: {},

    data() {
        return {
            initialLocal: "",
            isFullscreen: false,
            codeOptions: {
                styleActiveLine: true,
                styleSelectedText: true,
                smartIndent: true,
                spellcheck: true,
                autoCloseBrackets: true,
                colorize: true,
                matchBrackets: true,
                autocorrect: true,
                autoCloseTags: true,
                showCursorWhenSelecting: true,
                tabSize: 2,
                mode: mime[this.item.syntax || "sql"],
                foldGutter: true,
                lineWrapping: true,
                gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"],
                highlightSelectionMatches: { showToken: /\w/, annotateScrollbar: true },
                theme: "dracula",
                lineNumbers: true,
                keyMap: "sublime",
                line: true,
                hintOptions: {
                    completeSingle: false
                },
                readOnly: this.disabled || this.readonly ? "nocursor" : false,
                extraKeys: {
                    "Alt-F": this.fullscreen,
                    "Ctrl-S": this.save,
                    "Ctrl-U": this.undo,
                    Esc: this.exit
                }
            }
        }
    },

    computed: {
        cm() {
            return this.$refs.editor.cminstance
        },
        isSql() {
            return this.item.type === "code" && this.item.label === "sql" && this.$projectInfo.sql_assistant
        },
        hasUndo() {
            return this.local !== this.initialLocal
        }
    },

    mounted() {
        this.initialLocal = this.local
        this.$refs.editor.cminstance.setSize("100%", "35.5px")
    },

    methods: {
        fullscreen() {
            this.isFullscreen = true
            this.cm.setOption("fullScreen", true)
            this.cm.focus()
        },

        undo() {
            this.local = this.initialLocal
        },

        exit() {
            if (this.initialLocal !== this.local) {
                this.$platonApp.dangerToast(
                    this.$l("platon.attention", "Диққат"),
                    this.$l("platon.editor_exit_text", "Ўзгартиришларингиз сақлаб қолинмади")
                )
            }

            this.isFullscreen = false
            this.cm.setOption("fullScreen", false)
            this.updateLocal(this.initialLocal)
        },

        save() {
            this.initialLocal = this.local
            this.isFullscreen = false
            this.cm.setOption("fullScreen", false)
        },
        onCmReady(cm) {
            cm.on("keypress", () => {
                cm.showHint({ compeleteSingle: false })
            })
        },
        generateSql() {
            this.promptAction("SQL Generator Assistant", null, {
                rejectText: "Reject",
                confirmText: "Confirm",

                component: SqlGenerator,
                width: "50%"
            }).then((res) => {
                this.local = res.data ?? this.local
            })
        }
    }
}
</script>

<style lang="scss">
@import "../../../assets/styles/vars";

.fullscreen,
.undo {
    top: 0;
    z-index: 20;
    cursor: pointer;
    position: absolute;
    transition: all 0.3s;

    &:hover {
        transform: rotate(-45deg);
    }
}
.fullscreen {
    right: 0.5rem;

    &.sql-fullscreen {
        right: 4.5rem;
    }
}

.undo {
    right: 2rem;
    &.sql-undo {
        right: 6rem;
    }
}

.code-editor-btn {
    bottom: 24px;
    z-index: $code-editor-save-button-index;
    span {
        padding: 3px;
    }
}

.save-btn {
    right: 24px;
}
.undo-btn {
    right: 350px;
}
.cancel-btn {
    right: 175px;
}

.cm-matchhighlight {
    background-color: #006400;
}

.CodeMirror-selection-highlight-scrollbar {
    background-color: #006400;
}

.CodeMirror-hints {
    z-index: 9999 !important;
}

.cm-s-dracula .CodeMirror-matchingbracket {
    background-color: #3b514d;
    color: #ffef28 !important;
    font-weight: bold;
}

// .CodeMirror-scroll {
//   background-color: #050a1d !important;
// }

.CodeMirror-gutters {
    background-color: var(--pl-markdown-editor-bg);
}

// .cm-s-dracula .CodeMirror-gutters {
//   background-color: #050a1d !important;
// }

.pl-codemirror-wrapper {
    gap: 14px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & .vue-codemirror {
        width: 100%;
    }
}
</style>
